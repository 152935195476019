/* 기본 레이아웃 및 배경 설정 */
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%; /* ✅ 추가: 높이 100% 설정 */
  background-color: #ffffff;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;  /* ✅ 변경: auto → hidden으로 변경하여 스크롤 제거 */
  overflow-x: hidden; /* ✅ 가로 스크롤 방지 */
}

/* 코드 요소에 고정 폭 글꼴 설정 */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* 루트 div는 콘텐츠 크기에 맞게 유동적 */
#root {
  width: 100%;
  height: 100%; /* ✅ 추가: 높이 100% 설정 */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between; /* ✅ 추가: 콘텐츠를 상단과 하단에 분산 */
  overflow: hidden; /* ✅ 추가: 내부 요소 스크롤 방지 */
}

/* 메인 콘텐츠 영역 */
.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1200px;
  padding: 20px;
}

/* 저작권 정보 */
.copyright {
  width: 100%;
  text-align: center;
  font-size: 0.8rem;
  color: #666;
  padding: 10px 0;
  margin-top: auto; /* 남은 공간을 위쪽으로 밀어서 항상 하단에 위치 */
}

/* 헤더 및 로고 */
.logo-container {
  margin-bottom: 20px;
}

/* 설명 텍스트 */
.description {
  text-align: center;
  margin-bottom: 20px;
}

/* 버튼 스타일 */
.start-button {
  background-color: #5a4eeb;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.start-button:hover {
  background-color: #4a3fd7;
}